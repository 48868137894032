import YoutubeEmbed from "Components/YouTube";
import React, { useContext, useEffect, useRef, useState } from "react";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { ReportService } from "services/Report";
import { useNavigate } from "react-router-dom";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import DeleteIcon from '@mui/icons-material/Delete';
import HistoryIcon from '@mui/icons-material/History';
import QuizIcon from '@mui/icons-material/Quiz';
import ClearIcon from '@mui/icons-material/Clear';
import { userContext } from "Components/User";
import { useTranslation } from "react-i18next";

const { Dialog, DialogTitle, DialogContent, Button, Typography, Card, CardMedia, CardContent, useMediaQuery,
  useTheme, TextField, IconButton, CircularProgress, Box, Select, MenuItem, Divider, Collapse,
  InputAdornment, Popover,
  List,
  ListItem,
  ListItemButton,
} = require("@mui/material");

const ExtendedView = ({ media, open, setSelectedMedia }) => {
  const { t, i18n } = useTranslation();
  const [inProgress, setInProgress] = useState(false);
  const [aiResponse, setAiResponse] = useState(null);
  const [error, setError] = useState(null);
  const [query, setQuery] = useState(null);
  const [maxComments, setMaxComments] = useState(25);
  const uContext = useContext(userContext);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  const reportButtonRef = useRef(null);
  const dividerRef = useRef(null);

  const inputRef = useRef(null);

  const [anchorEl, setAnchorEl] = useState(null)
  const [anchorQuestionEl, setAnchorQuestionEl] = useState(null)

  const [openHistoryGroup, setOpenHistoryGroup] = useState(true);
  const [openQuestionGroup, setOpenQuestionGroup] = useState(true);

  const [recentPromps, setResentPromps] = useState([])

  const questions = [...Array(10).keys()].map(i => t(`extendedView.q${i + 1}`))

  const getPromptsVarName = () => {
    return uContext?.user ? uContext.user.username + "_csl_ai_prompts" : null
  }
  const getRecentPrompts = () => {
    const prompts = []
    if (uContext?.user && localStorage.getItem(getPromptsVarName())) {
      JSON.parse(localStorage.getItem(getPromptsVarName())).map(prompt => prompts.push(prompt))
    }
    return prompts
  }

  const saveToLocalStorage = (prompts) => {
    localStorage.setItem(getPromptsVarName(), JSON.stringify(prompts))
  }

  const storePrompt = () => {
    if (!query || recentPromps.includes(query)) {
      return
    }
    let prompts = getRecentPrompts()
    prompts.splice(0, 0, query)
    prompts = prompts.slice(0, 10)
    saveToLocalStorage(prompts)
    setResentPromps(prompts)
  }

  const onShowHistoryClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const onShowQuickQuestionsClick = (event) => {
    setAnchorQuestionEl(event.currentTarget);
  };

  const handleClose = () => {
    setSelectedMedia(null);
    setInProgress(false);
  }

  const handleMaxComments = (e) => {
    // if (!uContext.isTrial) {
    setMaxComments(e.target.value);
    // }
  };

  const focusTextArea = () => {
    if (inputRef.current) {
      inputRef.current.focus()
      inputRef.current.setSelectionRange(
        inputRef.current.value.length,
        inputRef.current.value.length
      )
    }
  }

  useEffect(() => {
    setResentPromps(getRecentPrompts())
  }, [])

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        reportButtonRef.current?.scrollIntoView({
          // block: "nearest",
          // inline: "center",
          behavior: "smooth",
          alignToTop: false
        });
      }, 500);
    } else {
      setAiResponse(null);
    }
  }, [open]);


  const getReport = () => {
    setInProgress(true);
    storePrompt()
    ReportService.getReport(media.id, media.channel_id, query, maxComments).then((report) => {
      if (report.resourceId === media.id) {
        setAiResponse(report.response);
        setTimeout(() => {
          dividerRef.current.scrollIntoView({
            block: "start",
            // inline: "center",
            behavior: "smooth",
            alignToTop: false
          });
        }, 500);
      }
    }).catch(async (response) => {
      if (response.status == 404) { // not found
        setError("notFound");
      } else {
        const err = await response.json();
        if (!!err && !!err.message && !!err.message.subscription_expired) {
          navigate(`/${i18n.language}/error?type=subscriptionExpired&expiredOn=${encodeURIComponent(err.message.subscription_expired)}`)
        } else if (err?.message === "ai_requests_limit") {
          // navigate("error?type=ai_requests_limit");
          navigate(`/${i18n.language}/error?type=ai_requests_limit`)
        } else {
          alert(t("global.oups"))
        }
      }
    }).finally(() => {
      setInProgress(false);
    })
  }

  const handleCloseHistoryPanel = () => {
    setAnchorEl(null);
  }

  const handleCloseQuestionsPanel = () => {
    setAnchorQuestionEl(null);
  }

  const getPromptItem = (prompt, type, index) => (
    <ListItem disablePadding key={"promptItem" + index}>
      <ListItemButton
        onClick={() => {
          setQuery((query ? query + "\n" : "") + prompt)
          if (type === "history") {
            handleCloseHistoryPanel()
          } else {
            handleCloseQuestionsPanel()
          }
          setTimeout(() => focusTextArea(), 100)
        }}>
        <Typography sx={{ fontSize: "small" }}>{prompt}</Typography>
      </ListItemButton>
      {
        type === "history" &&
        (
          <IconButton size="small" sx={{ mr: ".2em" }}
            onClick={() => {
              const updatedPrompts = recentPromps.filter((item) => item !== prompt)
              setResentPromps(updatedPrompts)
              saveToLocalStorage(updatedPrompts)
              if (updatedPrompts.length === 0) {
                handleCloseHistoryPanel()
              }
            }}
          >
            <ClearIcon sx={{ height: ".6em", width: ".6em" }} />
          </IconButton>
        )
      }
    </ListItem>
  )

  const promptHistoryPanel = () => (
    <Popover
      sx={{ marginRight: "-15px" }}
      id={"popover"}
      open={!!anchorEl}
      anchorEl={anchorEl}
      onClose={handleCloseHistoryPanel}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'right',
      }}
    >
      <List sx={{
        maxWidth: "500px",
        maxHeight: "500px",
        pl: 1
      }}>
        <Collapse in={openHistoryGroup} timeout="auto" unmountOnExit>
          {recentPromps.map((prompt, index) =>
            getPromptItem(prompt, "history", index)
          )}
        </Collapse>
      </List>
    </Popover>
  )

  const questionsPanel = () => (
    <Popover
      sx={{ marginRight: "-15px" }}
      id={"popover"}
      open={!!anchorQuestionEl}
      anchorEl={anchorQuestionEl}
      onClose={handleCloseQuestionsPanel}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'right',
      }}
    >
      <List
        sx={{
          maxWidth: "500px",
          maxHeight: "500px",
          listStyleType: 'auto',
          pl: 4,
          pr: 1,
          '& .MuiListItem-root': {
            display: 'list-item',
          }
        }}
      >
        <Collapse in={openQuestionGroup} timeout="auto" unmountOnExit>
          {questions.map((prompt, index) =>
            getPromptItem(prompt, "questions", index)
          )}
        </Collapse>
      </List>
    </Popover>
  )

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullScreen={fullScreen}
      maxWidth={"md"}
      fullWidth={true}
      scroll={'body'}
      disableRestoreFocus
    >
      <DialogTitle id="scroll-dialog-title">
        <Grid container alignItems={"center"}>
          <Grid flexGrow={1}>
            {t("extendedView.header")}
          </Grid>
          <Grid>
            <IconButton
              title={t("extendedView.closeDialog")}
              onClick={handleClose}
            >
              <CloseRoundedIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>

      <DialogContent >
        <Grid container spacing={3} display={"flex"} justifyContent={"center"}>
          <Grid xs="auto" >
            <Card sx={{ width: 280 }}>
              <CardMedia>
                <YoutubeEmbed embedId={media?.id || "null"} thumbnail={media.thumbnail.url} />
              </CardMedia>
              <CardContent>
                <Typography color="text.secondary"
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    WebkitLineClamp: '2',
                    WebkitBoxOrient: 'vertical',
                    // lineHeight: lineHeight
                  }}>
                  {media?.title}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid display={"flex"} flexGrow={1} >
            <Grid container flexGrow={1} display={"flex"} flexDirection="column" gap={1}>
              <Grid flexGrow={1} display={"contents"}>
                <TextField
                  autoFocus
                  id="ai-prompt"
                  sx={{
                    marginTop: ".5em",
                  }}
                  multiline
                  fullWidth
                  label={t("extendedView.promptFieldLabel-1")}
                  value={query || ""}
                  onChange={(event) => {
                    setError(false);
                    setQuery(event.target.value);
                  }}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter' && event.metaKey) {
                      getReport()
                    }
                  }}
                  inputProps={{
                    sx: {
                      minHeight: "6.5em",
                    },
                    // autoFocus: true,
                    ref: inputRef
                  }}
                  InputProps={{
                    sx: {
                      "paddingRight": 0
                    },
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignSelf: "start",
                          mr: ".6em",
                          flexGrow: 1,
                        }}
                      >
                        <Grid
                          container
                          flex={1}
                          flexGrow={1}
                          direction={"column"}
                          justify="space-between"
                        >
                          <Grid container direction={"column"} display="flex" justifyContent={"center"} >
                            <Divider sx={{ w: "2px", height: "100%", mr: ".2em" }} orientation="vertical" />
                            <IconButton
                              aria-label="ai-prompt"
                              title={t("extendedView.history")}
                              id="prompt-history-button"
                              onClick={onShowHistoryClick}
                              disabled={!recentPromps || recentPromps.length === 0}
                            >
                              {/* <BackupIcon sx={{ height: ".8em", width: ".8em" }} /> */}
                              <HistoryIcon sx={{ height: ".8em", width: ".8em" }} />
                            </IconButton>
                          </Grid>
                          <Grid container direction={"column"} display="flex" justifyContent={"center"}>
                            <Divider sx={{ w: "2px", height: "80%", mr: ".2em" }} orientation="vertical" />
                            <IconButton
                              // disabled={!query}
                              aria-label="ai-prompt"
                              title={t("extendedView.quickQuestions")}
                              onClick={onShowQuickQuestionsClick}
                            >
                              <QuizIcon sx={{ height: ".8em", width: ".8em", }} />
                            </IconButton>
                          </Grid>
                          <Grid
                            // display="flex"
                            flexGrow={1}
                            minHeight={".4em"}
                          >
                          </Grid>
                          <Grid container direction={"column"} display="flex" justifyContent={"center"}>
                            <Divider sx={{ w: "2px", height: "80%", mr: ".2em" }} orientation="vertical" />
                            <IconButton
                              disabled={!query}
                              aria-label="ai-prompt"
                              title={t("extendedView.clearAll")}
                              onClick={() => {
                                setQuery(null)
                                focusTextArea()
                              }}
                            >
                              <DeleteIcon sx={{ height: ".8em", width: ".8em", }} />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid display={"inline-flex"} alignItems={"baseline"} flexGrow={1} xs={12}>
                <Box sx={{ flexFlow: "column", flexGrow: 1, display: "flex", height: "100%", alignItems: "baseline" }}>
                  <Box display={"flex"} alignItems={"baseline"}>
                    <Typography>{t("extendedView.maxCommmentsProcess")}</Typography>
                    <Select
                      variant="standard"
                      labelId="demo-simple-select-readonly-label"
                      id="demo-simple-select-readonly"
                      value={maxComments}
                      label="Comments"
                      onChange={handleMaxComments}
                      // inputProps={{ readOnly: uContext.isTrial }}
                      sx={{ ml: "0.3em", mr: ".3em" }}
                    >
                      <MenuItem value={25}>25</MenuItem>
                      <MenuItem value={50}>50</MenuItem>
                      <MenuItem value={100}>100</MenuItem>
                    </Select>
                    <Typography>{t("extendedView.maxCommmentsMostRecentComments")}</Typography>
                  </Box>
                  <Box ref={dividerRef} flexGrow={1} display={"flex"} width={"100%"} pr={"1.5em"}>
                    <Divider sx={{ display: !!aiResponse ? "flex" : "none", flexGrow: 1, m: "1em 0" }} />
                  </Box>
                </Box>
                <Box sx={{ position: 'relative', alignSelf: "end" }}>
                  <Button
                    ref={reportButtonRef}
                    sx={{ height: "fit-content", color: "white" }}
                    variant="contained"
                    disabled={inProgress}
                    onClick={getReport}
                  >
                    {t("extendedView.getReport")}
                  </Button>
                  {inProgress && (
                    <CircularProgress
                      size={24}
                      sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginTop: '-12px',
                        marginLeft: '-12px',
                      }}
                    />
                  )}
                </Box>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            flexGrow={1}
            xs={12}
            spacing={1}
            display={!!aiResponse ? "" : "none"}
          >
            <Collapse in={!!aiResponse} sx={{pb:".5em"}}>
              <Typography sx={{ whiteSpace: "pre-line", borderLeft: "solid darkorange", pl: "1em" }}>
                {aiResponse ? aiResponse : null}
              </Typography>
            </Collapse>
          </Grid>
        </Grid>
      </DialogContent>

      {promptHistoryPanel()}
      {questionsPanel()}

    </Dialog >
  )
};

export default ExtendedView;